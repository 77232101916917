<template>
  <div class="err">
    <p class="errTitle">404</p>
    <p class="errSubtitle">{{$t('errors.pagenotfound')}}</p>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>

.err {
  min-height: 80vh; 
  padding-top: 5rem;
  text-align: center;
}

.errTitle {
  margin: 0;
  padding: 0;
  font-size: 30vh;
}

.errSubtitle {
  margin: 0;
  padding: 0;
  font-size: 10vh;
}

</style>