<template>
  <div class="main-area center-text cloud-image">
    <div class="display-table">
      <div class="display-table-cell">
        <h1 class="title font-white"><b>{{$t('cloud.slogan')}}</b></h1>
        <h4 class="desc font-white">{{$t('cloud.subslogan')}}</h4>
      </div><!-- display-table -->
    </div><!-- display-table-cell -->
  </div><!-- main-area -->
</template>

<script>

export default {
  name: 'Cloud',
  components: {
  }
}
</script>

<style scoped>

.cloud-image {
  background-image:url(../assets/clouds.jpg);
}

</style>
