<template>
  <b-container style="padding-top: 8rem; padding-bottom: 4rem;">
    <PrivacyDE/>
  </b-container>
</template>

<script>
import PrivacyDE from '@/components/legal/privacy/PrivacyDE.vue'
export default {
  components: {
    PrivacyDE,
  }
}
</script>

<style>

</style>