<template>
  <div>
    <div class="main-area center-text">
      <div class="display-table">
        <div class="display-table-cell">
          <h1 class="title font-white"><b>Kontaktformular</b></h1>
          <p class="desc font-white">
            Verwenden Sie dieses Formular um mit uns in Kontakt zu treten.
          </p>
          <b-input
            type="text"
            v-model="name"
            placeholder="Name"
            :required="true"
            class="desc"></b-input>
          <b-input
            type="email"
            v-model="mail"
            placeholder="E-Mail"
            :required="true"
            :state="mail.length === 0 ? null : mailValid"
            class="desc"
        ></b-input>
        <b-form-textarea
      id="textarea"
      v-model="text"
      :required="true"
      placeholder="Nachricht…"
      rows="3"
      max-rows="6"
      class="desc"
    ></b-form-textarea>
          <br>
          <a class="notify-btn"><b>Senden</b></a>
        </div><!-- display-table -->
      </div><!-- display-table-cell -->
    </div><!-- main-area -->
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    data () {
      return {
        name: '',
        mail: '',
        text: '',
      };
    },
    computed: {
    mailValid() {
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      return regex.test(this.mail);
    },
  }
  }
</script>

<style>

</style>